<template>
    <svg viewBox="0 -10 325 350" fill="none" xmlns="http://www.w3.org/2000/svg" style="display: inline-block">
        <g id="iconPersonCircle">
            <!-- #A4B5BA -->
            <circle id="bg" cx="162" cy="153" r="141.5" fill="var(--inactive-main-navy)" stroke="" stroke-width="23"/>
            <g id="person" clip-path="url(#clip0_359_11)">
                <g id="Group">
                    <path id="Vector" d="M183.779 164.996C213.48 152.694 227.584 118.643 215.281 88.9422C202.979 59.2414 168.928 45.1375 139.227 57.4403C109.526 69.743 95.4226 103.794 107.725 133.494C120.028 163.195 154.079 177.299 183.779 164.996Z" fill="white"/>
                    <path id="Vector_2" d="M240.257 215.871C222.926 198.275 199.954 188.585 175.567 188.585H147.434C123.048 188.585 100.074 198.275 82.744 215.871C65.4988 233.381 56.002 256.494 56.002 280.955C56.002 284.839 59.151 287.988 63.035 287.988H259.965C263.849 287.988 266.998 284.839 266.998 280.955C266.999 256.495 257.501 233.381 240.257 215.871Z" fill="white"/>
                </g>
            </g>
            <circle id="outline" cx="162" cy="162" r="141" stroke="var(--inactive-main-navy)" stroke-width="42"/>
        </g>
        <defs>
            <clipPath id="clip0_359_11">
            <rect width="235" height="235" fill="white" transform="translate(44 53)"/>
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
name: 'iconPerson',
}
</script>

<style scoped>

</style>
